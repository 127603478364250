@charset 'utf-8';
@import "~bulma/sass/utilities/functions";
@import "./styles/custom-variables";
@import "./styles/animations";
@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');

html {
  margin: 0px;
  padding: 0px;
  overflow-y: auto !important;
  scroll-behavior: smooth;
}

body {
  margin: 0px;
  padding: 0px;
}

#root {
  margin: 0px;
  padding: 0px;
  font-family: 'Roboto', sans-serif;
}

.app-screen {
  animation: fadeInTransparent 0.4s ease-out 1;
}

// Import Bulma styles
@import "~bulma/bulma";

// Import rbx style fixes
@import "~rbx/rbx";

@import '~bulma-tooltip';