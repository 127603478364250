@import "./custom-variables";
@import "./animations";

//Login animation:

.login-card {
  min-height: 520px;
  opacity: 0;
  transform: translateY(80px);
  transition: transform 0.4s ease-out, opacity 0.4s;
}

// Custom checkbox:

.checkbox-container {
  display: block;
  position: relative;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.checkbox-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.checkmark {
  position: absolute;
  top: 8px;
  left: 10px;
  height: 25px;
  width: 25px;
  border-radius: 4px;
  border: 2px solid rgb(58, 144, 224);
}

.checkbox-container input:checked ~ .button .checkmark {
  background-color: #2196F3;
}

.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.checkbox-container input:checked ~ .button .checkmark:after {
  display: block;
}

.checkbox-container .checkmark:after {
  left: 7px;
  top: 2px;
  width: 8px;
  height: 15px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

// Error warning button:

.no-margin-buttons {
  margin-bottom: 0px !important;
}