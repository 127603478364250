@import "./custom-variables";
@import "./animations";

// Workspace general settings:

.main-section {
  margin-top: 56px;
}

.workspace {
  height: calc(100vh - 56px);
  background-color: #f7f7f7;
}

.workspace-column {
  overflow-x: hidden;
  overflow-y: auto;
}

.workspace-section {
  animation: fadeIn 0.15s ease-out 1;
}

.workspace-title {  
  font-size: 1.5rem;
  color: rgb(5, 124, 192);
  text-align: left;
}

@media screen and (min-width: 1024px) {
  .workspace-title {
    font-size: 2rem;
  }
}

.workspace-content {
  padding: 1rem;
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
  margin-bottom: 1.5rem;
  background-color: #fbfbfb;
  border-radius: 10px;
  box-shadow: 0.25em 0.5em 0.75em 0.125em rgba(#000, 0.3);
}

@media screen and (min-width: 1024px) {
  .workspace-content {
    padding: 1.75rem;
  }
}

//Workspace scrollbar settings:

.workspace-column,
.workspace-column .table-container,
.thin-scroller {
  scrollbar-color: #80aeb1 #dddddd;
  scrollbar-width: thin;
}

.workspace-column::-webkit-scrollbar,
.workspace-column .table-container::-webkit-scrollbar {
  width: 12px;
}

.workspace-column::-webkit-scrollbar-track,
.workspace-column .table-container::-webkit-scrollbar-track {
  background-color: #dddddd;  
}

.workspace-column::-webkit-scrollbar-thumb,
.workspace-column .table-container::-webkit-scrollbar-thumb {
  background: #80aeb1; 
  border: 1px solid #e7f4ff;
}

.workspace-column::-webkit-scrollbar-thumb:hover,
.workspace-column .table-container::-webkit-scrollbar-thumb:hover {
  background: #488b8f; 
}

.workspace-column::-webkit-scrollbar-thumb:active,
.workspace-column .table-container::-webkit-scrollbar-thumb:active {
  background: rgb(28, 159, 168); 
}

// Other settings:

.title-divider {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  border-top: 2px solid rgb(121, 193, 226);
}

.card-divider {
  margin-top: 0.5rem;
  margin-bottom: 1rem;
  border-top: 2px solid rgb(233, 233, 233);
}

.form-divider {
  margin-top: 0.75rem;
  margin-bottom: 0.75rem;
  border-top: 2px solid rgb(206, 206, 206);
}

.one-digit-input {
  min-width: 55px;
  max-width: 55px;
}

.two-digit-input {
  min-width: 75px;
  max-width: 75px;
}

.uf-input {
  min-width: 90px;
  max-width: 90px;
}

.time-input {
  min-width: 110px;
  max-width: 110px;
}

.date-input {
  min-width: 150px;
  max-width: 150px;
}

.small-column {
  min-width: 130px;
  max-width: 130px;
}

.label {
  margin-bottom: 0rem !important;
}

.label-2px-mt {
  margin-top: 2px !important;
}

.label-button {
  font-weight: normal !important;
  justify-content: left;
}

.field-column {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}

.static-field {
  border-bottom: 1px solid rgb(211, 211, 211);
}

.field-column.cnpj {
  min-width: 210px;
}

.repeat-times {
  max-width: 56px;
}

.weekday-button {
  display: inline-block;
  margin-left: 5px;
  margin-top: 6px;
  width: 22px;
  height: 22px;
  border-radius: 99px;
  font-size: 14px;
  text-align: center;
  background-color: #e7e7e7;
  cursor: pointer;
  user-select: none;
}

.card-section {  
  padding-left: 2rem;
  padding-right: 2rem;
  padding-top: 1.5rem;
  padding-bottom: 1rem;
}

@media screen and (max-width: 1024px) {
  .card-content {
    padding-left: 0.75rem;
    padding-right: 0.75rem;
    padding-top: 0.75rem;
  }
}

@media screen and (max-width: 1024px) {
  .card-section {
    padding-left: 1rem;
    padding-right: 1rem;
  }
}

.search-card {
  margin-bottom: 2rem;
 }

.card-title {  
  font-size: 1.75rem;
  color: rgb(49, 153, 214);
  text-align: left;
}

.card-header-icon {
  cursor: auto !important;
}

.card.has-border {
  border: solid 2px #d5d5d5
}

.result-header {  
  border: 2px solid black;
}

.fullwidth-button {
  display: block;
  width: 100%;
}

.success-icon {
  color: #48c78e !important;
}

.button.is-small:not(.is-rounded) {
  border-radius: 4px !important;
}

.clinical-exams-label-column {
  min-width: 200px;
}

// Custom checkbox:

.checkbox-container {
  display: block;
  position: relative;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.checkbox-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.checkmark {
  position: absolute;
  top: 5px;
  left: 4px;
  height: 22px;
  width: 22px;
  border-radius: 4px;
  border: 2px solid #00CC66;
}

.checkbox-container input:checked ~ .button .checkmark {
  background-color: #00CC66;
}

.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.checkbox-container input:checked ~ .button .checkmark:after {
  display: block;
}

.checkbox-container .checkmark:after {
  left: 6px;
  top: 1px;
  width: 7px;
  height: 13px;
  border: solid white;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.checkbox-button {
  padding-left: 2rem;
  text-decoration: none !important;
  justify-content: left;
}

.switch-button {
  text-decoration: none !important;
  display: -webkit-inline-box;
}

//Retirado: não mostrar tooltip ao focar no input:

/* [data-tooltip]:focus-within::before,
[data-tooltip]:focus-within::after {
  display: none !important;
} */

.flip-arrow {
  transform: rotateZ(180deg);;
}

.hide-filters {
  display: none;
}

th {
  vertical-align: middle !important;
}

.cursor-pointer {
  cursor: pointer;
}

.search-dropdown {
  width: 100%;
}

.checkboxes-container {
  background-color: #ffffff;
  padding-top: 2px;
  padding-bottom: 2px;
  padding-left: 4px;
  padding-right: 4px;
  border: 1px solid rgb(233, 233, 233);
  border-radius: 5px;
}

.table-column-small {
  width: 90px;
}

.table-column-medium {
  width: 105px;
}

.table-container table {
  overflow: hidden;
}

.table td{
  vertical-align: middle !important;
}

.vertical-align-middle {
  vertical-align: middle !important;
}

.tabs li a {
  font-size: 0.75rem;
  border-bottom: 2px solid rgb(235, 235, 235);
}

.tabs li a:hover {
  border-bottom: 2px solid rgb(255, 193, 100);
}

.tabs li.is-active a {
  font-weight: 700;
  font-size: 0.875rem;
  padding-top: 0.125rem;
  border-bottom: 3px solid rgb(255, 115, 0);
}

.tabs.is-boxed li.is-active a {
  padding-top: 0.5rem;
  padding-bottom: 0.375rem;
}

.tabs.is-boxed a {
  background-color: #ebebeb;
  margin-right: 3px;
}

@media screen and (min-width: 1024px) {
  .tabs li a {
    font-size: 0.875rem;
  }

  .tabs li.is-active a {
    font-weight: 700;
  }
}

.tabs {
  scrollbar-color: #80aeb1 #dddddd;
  scrollbar-width: thin;
}

.tabs::-webkit-scrollbar {
  width: 5px !important;
  height: 5px !important;
}

.tabs::-webkit-scrollbar-track {
  background-color: #dddddd !important;  
  border-top: 1px solid #ffffff !important;
}

.tabs::-webkit-scrollbar-thumb {
  background: #80aeb1 !important; 
  border-top: 1px solid #ffffff !important;
}

.tabs::-webkit-scrollbar-thumb:hover {
  background: #488b8f !important; 
}

.tabs::-webkit-scrollbar-thumb:active {
  background: rgb(28, 159, 168) !important; 
}

.tabs-content {
  padding: 1rem;
  padding-top: 1.5rem;
  padding-bottom: 2rem;
  margin-top: -1.5rem;
  margin-bottom: 1.5rem;
  background-color: #fbfbfb;
  border-radius: 10px;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
  box-shadow: 0.25em 0.5em 0.75em 0.125em rgba(#000, 0.3);
}

@media screen and (min-width: 1024px) {
  .tabs-content {
    padding: 1.75rem;
  }
}

.secondary-tabs {
  font-size: 0.5rem;
  margin-top: -1rem;
  margin-left: -0.75rem;
  margin-right: -0.75rem;
}

.secondary-tabs .tabs li a {
  font-size: 0.875rem;
}

.secondary-tabs .tabs li.is-active a {
  font-weight: 700;
  font-size: 1rem;
}

// Calendar:

.calendar-section {
  padding-top: 0.75rem;
  padding-bottom: 0.5rem;
}

@media screen and (max-width: 1024px) {
  .calendar-section {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
}

.calendar-title {  
  font-size: 1.25rem;
  color: rgb(18, 130, 194);
  text-align: left;
}

.calendar-title-columns {  // Somente aparece no mobile
  margin-bottom: -0.75rem !important;
}

.calendar-level {
  margin-bottom: 0.5rem !important;
}

.fc-view-harness {
  transform: translateX(0);
  transition: transform 0.1s ease-out, opacity 0.1s;
  user-select: none;
}

.fc-view {
  background-color: #ffffff;
}

.fc .fc-button-primary {
  background-color: #3489d8 !important;
  border-color: #ffffff !important;
}

.fc .fc-button-primary.fc-button-active {
  background-color: #25c06b !important;
}

.fc-toolbar-title {
  color: rgb(18, 130, 194);
  font-weight: 700;
}

.fc .fc-toolbar.fc-header-toolbar {
  margin-bottom: 1.5em;
}

@media screen and (max-width: 1024px) {
  .fc-toolbar-title,
  .fc-today-button {
    display: none !important;
  }
  .fc .fc-button {
    font-size: 0.875rem !important;
  }
  .fc .fc-toolbar.fc-header-toolbar {
    margin-bottom: 0.5em !important;
  }
}

.fc th {
  text-align: center !important;
}

.fc-scroller { 
  margin-bottom: 0px !important;
  overflow-y: auto !important;
  scrollbar-color: #80aeb1 #dddddd;
  scrollbar-width: thin;
 }

.fc-scroller::-webkit-scrollbar {
  width: 9px !important;
}

.fc-scroller::-webkit-scrollbar-track {
  background-color: #dddddd !important;  
}

.fc-scroller::-webkit-scrollbar-thumb {
  background: #80aeb1 !important; 
  border: 1px solid #e7f4ff !important;
}

.fc-scroller::-webkit-scrollbar-thumb:hover {
  background: #488b8f !important; 
}

.fc-scroller::-webkit-scrollbar-thumb:active {
  background: rgb(28, 159, 168) !important; 
}

.fc .fc-timegrid-slot-minor {
  border-top-style: solid !important;
  border-top-color: #f0f0f0 !important;
}

.fc-timegrid-slot-label {
  background-color: #fbfbfb !important;
}

.fc thead {
  background-color: #eee !important;
}

.fc-direction-ltr .fc-toolbar > * > :not(:first-child) {
  margin-left: 0em !important;
}

.fc .fc-daygrid-day.fc-day-today,
.fc .fc-timegrid-col.fc-day-today {
  background-color: rgba(0, 183, 255, 0.15) !important;
}

.fc a {
  color: #5a5a5a
}

.fc-timegrid-event {
  box-shadow: 0.125em 0.25em 0.5em 0.125em rgba(#000, 0.3) !important;
}

.fc-timegrid-event:focus {
  border: 2px solid rgb(134, 134, 134) !important;
}

@media screen and (max-width: 1024px) {
  .fc-list-event {
    font-size: 0.875rem;
  }
}

.fc-list-event {
  cursor: pointer
}

.event-icon {
  padding-left: 5px;
  padding-right: 5px;
  border: 1px solid #fff;
  border-radius: 99px;
  font-family: sans-serif;
  font-weight: 400;
}

.event-tooltip {
  animation: fadeIn 0.2s ease-out 1;
  min-width: 170px;
  max-width: 240px;
  z-index: 10;
  position: fixed;
  font-size: 0.875rem;
}

.event-tooltip::after {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  border-width: 10px;
  border-style: solid;
  border-bottom: 0px;
  border-color: #ffffff transparent transparent transparent;
  bottom: -10px;
  z-index: 1020;
}

.event-tooltip-left::after {
  left: 50px;
}

.event-tooltip-right::after {
  right: 50px;
}

.date-click-menu {
  z-index: 10;
  position: fixed;
}

.event-options {
  animation: fadeInTransparent 0.2s ease-out 1;
  min-width: 170px;
  max-width: 220px;
  z-index: 10;
  position: fixed;
  font-size: 0.875rem;
}

// Datepicker config:

.react-datepicker__time-list {
  scrollbar-color: #a7a7a7 #dddddd;
  scrollbar-width: thin;
}

.react-datepicker__time-list::-webkit-scrollbar {
  width: 10px !important;
}

.react-datepicker__time-list::-webkit-scrollbar-track {
  background-color: #dddddd !important;  
}

.react-datepicker__time-list::-webkit-scrollbar-thumb {
  background: #a7a7a7 !important; 
  border: 1px solid #eeeeee !important;
}

.react-datepicker__time-list::-webkit-scrollbar-thumb:hover {
  background: #929292 !important; 
}

.react-datepicker__time-list::-webkit-scrollbar-thumb:active {
  background: #7a7a7a; 
}

.react-datepicker-popper {
  z-index: 500 !important;
}

.react-datepicker {
  animation: showDropdown 200ms ease forwards;
}

.react-datepicker__triangle {
  transform: translate(45px, 0px) !important;
}

@keyframes showDropdown {
  0% {
    transform-origin: top;
    transform: scaleY(0);
  }
  100% {
    transform-origin: top;
    transform: scaleY(1);
  }
}

// DraftJS (editor de texto):

.editor {
  box-sizing: border-box;
  border: 1px solid #ddd;
  cursor: text;
  padding: 16px;
  border-radius: 2px;
  box-shadow: inset 0px 1px 8px -3px #ABABAB;
  background: #fefefe;
}

.public-DraftEditor-content {
  min-height: 53px;
  max-height: 400px;
  overflow: auto;
}

.editor h1 {
  font-size: 2.75rem;
}

.editor h2 {
  font-size: 2.25rem;
}

.editor h3 {
  font-size: 1.75rem;
}

.text-editor-button {
  background: #fbfbfb;
  color: #888;
  font-size: 18px;
  border: 0;
  padding-top: 0px;
  vertical-align: bottom;
  height: 34px;
  font-weight: bold;
}

.text-editor-button:hover {
  background: #f0f0f0;
}

.public-DraftStyleDefault-ul,
.public-DraftStyleDefault-ol {
  margin: 0px !important;
}

// Chart settings:

.chart-container {
  height: 320px;
  padding-top: 2.25rem;
  padding-bottom: 2.25rem;
  box-shadow: 0em 0em 0.5em 0em rgba(#000, 0.3);
}

.finance-chart-container {
  height: 320px;
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}

.recharts-legend-wrapper {
  right: 0px !important
}

// ColorPicker:

.professional-color {
  background: transparent;
  height: 100%;
  width: 100%;
  cursor: pointer;
  position: relative;
  outline: none;
  border-radius: 50%;
  transition: box-shadow 100ms ease 0s;
}

.color-picker-container {
  animation: fadeIn 0.2s ease-out 1;
  z-index: 10;
  position: absolute;
  font-size: 0.875rem;
}

// Appear from top:

.appear-from-top {
  animation: showDropdown 200ms ease forwards;
  transition: height 200ms;
}

// Others:

.document-check {
  color: #0a0;
  background-color: #efe;
  border-radius: 4px;
  margin-left: 5px;
}

.home-card {
  height: 430px;
}

