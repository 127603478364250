nav.navbar {
  user-select: none;
}

.navbar-title {
  color: #fff;
  font-weight: 600;
  font-size: 1.25rem;
}

.sidebar-menu-dropdown-arrow {
  right: 1.125rem;
  border: 5px solid rgb(0, 0, 0);
  border-radius: 2px;
  border-right: 0;
  border-top: 0;
  display: block;
  margin-top: -0.125em;
  pointer-events: none;
  top: 50%;
  transform: rotate(-45deg);
  transform-origin: center;
  height: 0.625em;
  width: 0.625em;
}

.sidebar-menu-dropdown-arrow::before {
  content: "";
  right: 1.125rem;
  border: 3px solid rgb(255, 255, 255);
  border-radius: 2px;
  border-right: 0;
  border-top: 0;
  display: block;
  margin-left: -0.25em;
  margin-top: -0.1em;
  pointer-events: none;
  height: 0.625em;
  width: 0.625em;
}

.navbar-item {
  align-items: unset;
}

.navbar-item img {
  max-height: 2.25rem;
}

.navbar-dropdown a.navbar-item {
  display: flex;
  flex-direction: column;
}

.menu-toogle {
  text-shadow: rgb(0, 0, 0) 1px 1px 2px;
}