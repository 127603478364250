@import "./custom-variables";
@import 'bulma/sass/utilities/mixins';
@import './animations.scss';

.app-loading-overlay,
.overlay-index-200 {
  animation: fadeInOverlay 0.2s ease-out 1;
}

.app-loading-overlay {
  @include overlay(0rem);
  background-color: #999;
  opacity: 0.5;
  z-index: 200;
  position: fixed;
}

.overlay-index-200 {
  @include overlay(0rem);
  background-color: rgb(0, 0, 0);
  opacity: 0.5;
  z-index: 200;
  position: fixed;
}

.overlay-index-100 {
  @include overlay(0rem);
  background-color: rgb(0, 0, 0);
  opacity: 0.4;
  z-index:100;
  position: fixed;
}

.login-loader {
  border: 14px solid rgb(171, 228, 255);
  border-top: 14px solid #40abe9;
  border-radius: 50%;
  width: 140px;
  height: 140px;
  animation: spin 0.5s linear infinite;
  margin-top: 60px;
  margin-bottom: 60px;
  margin-left: auto;
  margin-right: auto;
}

.workspace-loader {
  border: 16px solid #2b4352;
  border-top: 16px solid #00a2ff;
  border-radius: 50%;
  width: 160px;
  height: 160px;
  animation: spin 0.5s linear infinite;
  margin: auto;
  margin-top: calc(50vh - 80px);
}

.app-loader {
  border: 16px solid #aabbc4;
  border-top: 16px solid #00a2ff;
}

.element-loader {
  border: 0.15rem solid #2b4352;
  border-top: 0.15rem solid #00a2ff;
  border-radius: 50%;
  width: 1rem;
  height: 1rem;
  animation: spin 0.5s linear infinite;
  margin: auto;
}

.image-loader {
  border: 0.7rem solid #adebee;
  border-top:0.7rem solid #00a2ff;
  border-radius: 50%;
  width: 7rem;
  height: 7rem;
  animation: spin 0.5s linear infinite;
  margin: auto;
}

.loader-icon {
  border: 0.2rem solid #adebee;
  border-top:0.2rem solid #00a2ff;
  border-radius: 50%;
  width: 1.25rem;
  height: 1.25rem;
  animation: spin 0.5s linear infinite;
  margin: auto;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}