@import "./custom-variables";

$menu-label-spacing: 0px;
$menu-label-font-size: 1rem;

// Menu general settings:

.menu-column {  
  overflow-x: hidden;
  overflow-y: auto;
  height: 100%;
  min-width: 290px;
  max-width: 290px;
  background-color: #d6f7fa;
  user-select: none;
}

.menu {
  height: 100%;
  background-color: #d6f7fa;
}

//Menu scrollbar settings:

.menu-column {
  scrollbar-color: #6fc4ca #dddddd;
  scrollbar-width: thin;
}

.menu-column::-webkit-scrollbar {
  width: 9px;
}

.menu-column::-webkit-scrollbar-track {
  background-color: #dddddd;
  border: 1px solid #e7f4ff;
}

.menu-column::-webkit-scrollbar-thumb {
  background: #6fc4ca; 
  border: 1px solid #e7f4ff;
}

.menu-column::-webkit-scrollbar-thumb:hover {
  background: #82bcc0; 
}

.menu-column::-webkit-scrollbar-thumb:active {
  background: #61a2a7; 
}

//Logo image settings:

.menu-column .logo-section-container {
  background-color: #ffffff;
  border: 12px solid #92d7df;
  border-radius: 25px;
  width: 290px;
}

.menu-column .logo-section {
  cursor: pointer;
  min-height: 150px;
  max-width: 280px;
}

.logo-image {
  max-height: 85px;
}

//Menu children settings:

.label-icon {
  max-width: 40px;
}

.label-name {
  min-width: 210px;
  max-width: 210px;
}

.dropdown-item.sidebar-menu-item {
  color: #0d1d3a;
  font-size: 1rem;
}

.menu-label {
  line-height: 1.5 !important;
  display: flex;
  flex-direction: column;
  font-weight: 600;
  text-transform: none;
  cursor: pointer;
}

.menu-label.is-focused,
.menu-label.is-focused:hover
{
  background-color: #adebee;
}

.menu-list a.is-focused,
.menu-list a.is-focused:hover
{
  background-color: #0093a0;
  color: #FFFFFF;
  border-left: 5px solid #002fff;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
}

.menu-list a.is-focused .dropdown-item {
  color: #FFFFFF;
}

.menu-label-divider {
  height: 1px;
  background-color: #92d7df;
  margin-top: 0rem;
  margin-bottom: 0rem;
  margin-left: 50px;
  margin-right: 10px;
  border-bottom: 1px solid #92d7df;
}

.company-info-divider {
  height: 1px;
  background-color: #7fafb4;
  margin-top: 0rem;
  margin-bottom: 0rem;
  margin-left: 10px;
  margin-right: 5px;
  border-bottom: 2px solid #7fafb4;
}

.menu-label-arrow {
  border: 2px solid #222244;
  border-radius: 1px;
  border-right: 0;
  border-top: 0;
  display: block;
  margin-top: 0.4em;
  pointer-events: none; 
  transform: rotate(-45deg);
  transform-origin: center;
  height: 0.5em;
  width: 0.5em;
}

.menu-label-arrow.is-white {
  border-color: #FFFFFF;
}

.menu-label-arrow.flip-arrow {
  animation: flipArrow 100ms ease forwards;
}

@keyframes flipArrow {
  0% {
    transform: rotateZ(-45deg);
  }
  100% {
    transform: rotateZ(135deg) translateY(-3px) translateX(3px);
  }
}

.menu-label-arrow:not(.flip-arrow) {
  animation: unflipArrow 100ms ease forwards;
}

@keyframes unflipArrow {
  0% {
    transform: rotateZ(135deg);
  }
  100% {
    transform: rotateZ(-45deg);
  }
}



.menu-label:hover,
.menu-list a:hover {
  color: #0749c4;
  background-color: #adebee;

}

// Menu on mobile settings:

.dropdown-menu {
  transition: height 200ms, opacity 200ms;
  padding-top: 0px;
}

.dropdown.is-active .dropdown-menu {
  animation: showDropdown 200ms ease forwards;
  opacity: 1;
}

.dropdown.is-up .dropdown-menu {
  animation: showDropdownUp 200ms ease forwards !important;
  opacity: 1;
}

// Menu dropdowns animation:

.menu-list {
  transition: height 200ms, opacity 200ms;
}

.menu-list.show-dropdown {
  animation: showDropdown 200ms ease forwards;
  height: 65px;
  opacity: 1;
}

.menu-list.show-dropdown.height-is-1 {
  height: 40px;
}

.menu-list.show-dropdown.height-is-2 {
  height: 75px;
}

.menu-list.show-dropdown.height-is-3 {
  height: 112px;
}

.menu-list.show-dropdown.height-is-4 {
  height: 142px;
}

.menu-list.show-dropdown.height-is-5 {
  height: 180px;
}

.menu-list.show-dropdown.height-is-6 {
  height: 215px;
}

.menu-list.show-dropdown.height-is-7 {
  height: 265px;
}

@keyframes showDropdown {
  0% {
    transform-origin: top;
    transform: scaleY(0);
  }
  100% {
    transform-origin: top;
    transform: scaleY(1);
  }
}

@keyframes showDropdownUp {
  0% {
    transform-origin: top;
    transform: scaleY(0);
  }
  100% {
    transform-origin: top;
    transform: scaleY(1);
  }
}

.menu-list.hide-dropdown {
  animation: hideDropdown 200ms ease forwards;
  height: 0px;
  opacity: 0;
}

@keyframes hideDropdown {
  0% {
    transform-origin: top;
    transform: scaleY(1);
  }
  100% {
    transform-origin: top;
    transform: scaleY(0);
  }
}