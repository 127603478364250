@keyframes appearFromBottom {
  0% { 
    margin-top: 80px;
    opacity: 0;
  }
  100% {
    margin-top: 0px;
    opacity: 1;
  }
}

@keyframes fadeIn {
  0% { 
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeInTransparent {
  0% { 
    opacity: 0;
  }
  100% {
    opacity: 0.9;
  }
}

@keyframes fadeInOverlay {
  0% { 
    opacity: 0;
  }
  100% {
    opacity: 0.5;
  }
}

@keyframes showDropdown {
  0% {
    transform-origin: top;
    transform: scaleY(0);
  }
  100% {
    transform-origin: top;
    transform: scaleY(1);
  }
}

@keyframes showDropdownUp {
  0% {
    transform-origin: bottom;
    transform: scaleY(0);
  }
  100% {
    transform-origin: bottom;
    transform: scaleY(1);
  }
}