// Custom colors:

$custom-green-dark: #228888;
$custom-green-light: #D5EFEA;

$custom-colors: (
  "custom-green-dark": ($custom-green-dark),
  "custom-green-light": ($custom-green-light)
);

// Section on mobile:

$section-padding: 1.5rem 0.5rem;

// Navbar:

$navbar-height: 3.5rem;

// Sidebar Menu:

$menu-label-spacing: 0rem;
$menu-label-font-size: 1rem;
$menu-label-letter-spacing: 0rem;
$menu-label-color: #0d1d3a;
$menu-item-color: #0d1d3a;

//Tooltip:

$tooltip-padding: .25rem .75rem;

// Card:

$card-background-color: #fbfbfb;
$card-shadow: 0.25em 0.5em 0.75em 0.125em rgba(#000, 0.3);
$card-header-padding: 0.5rem 0.5rem;
$card-content-padding: 0.875rem 1.5rem 1.5rem 1.5rem;

// Box:

$box-shadow: 0.25em 0.5em 0.75em 0.125em rgba(#000, 0.3);

// Table:

$table-head-background-color: #e9e9e9;
$table-row-hover-background-color: #c7e6f5;
$table-striped-row-even-hover-background-color: #c7e6f5;
$table-head-cell-color: #2b2b2b;
$table-striped-row-even-background-color: #f2f2f2;

// Input:

$size-6: 0.875rem;

// Tabs:

$tabs-boxed-link-hover-background-color: #f0f0f0;
$tabs-boxed-link-radius: 10px;


// Modal (usado no New Event "Novo Agendamento"):

$modal-content-width: unset;
